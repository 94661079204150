const cgus: Record<string, string> = {
  SAMSUNG: `
    <h3>
      Faites réparer votre smartphone Samsung par le leader de la réparation en
      France
    </h3>
    <p>
      Save assure la <strong>réparation de smartphones
      Samsung</strong> près de chez vous avec une réactivité et une qualité de
      service exceptionnelles. En cas de problème, vous pouvez compter sur le
      plus grand réseau de France en restauration de smartphones. Avec près de
      200 magasins dans toute la France, bénéficiez d’une réparation de
      proximité efficace, réactive et conforme aux normes des constructeurs.
    </p>
    <p>
      Depuis cette page, créez instantanément votre bon de réparation en
      indiquant le modèle et les détails de l’incidence, et faites prendre en
      charge encore plus rapidement votre Galaxy (ou tout autre smartphone du
      constructeur coréen) dans un magasin près de chez vous.
    </p>
    <h3>Des réparations en 40 minutes chrono</h3>
    <p>
      Réparation d’écran, remplacement de batterie, smartphone cassé ou oxydé,
      changement de composant... Rallongez la durée de vie de votre mobile en
      optant pour la qualité et la rapidité d’une prestation professionnelle
      assurée par nos techniciens experts. Spécialistes de toutes les
      principales séries de mobiles Samsung, nous mettons en œuvre une
      <strong>Réparation en 40 minutes (dans 82% des cas)</strong> à partir du
      diagnostic. Quelle que soit la détérioration, votre smartphone sera remis
      en état de façon efficace et durable, avec tout le confort de la
      <strong>Garantie 1 an</strong>.
    </p>
    <h3>Un réparateur Samsung à côté de chez vous</h3>
    <p>
      Faites appel à des techniciens spécialisés Samsung proches de chez vous.
      Notre réseau de magasins répartis sur toute la France nous a permis
      d’établir une relation de confiance à travers des réparations garanties et
      aux normes. Profitez de l’efficacité et de la réactivité d’un réparateur
      de proximité. Grâce à notre <strong>agrément constructeur</strong>, Point
      Service Mobiles dispose de pièces détachées d’origine. En cas
      d’immobilisation, nous pouvons vous prêter un téléphone de remplacement
      (sous réserve de stocks disponibles).
    </p>
    <h3>Une expertise à toute épreuve</h3>
    <p>
      Votre smartphone nécessite un diagnostic immédiat et une réparation
      professionnelle ? Samsung Galaxy série A, J ou S, Note, Ace ou Grand...
      Quel que soit le modèle de votre mobile, faites appel à nos équipes de
      <strong>techniciens experts</strong> pour prolonger sa durée de vie de
      quelques années. Nous prenons en charge tous types d’avaries, que ce soit
      pour dépanner un écran noir, changer un écran LCD ou une vitre tactile,
      réparer les écouteurs internes ou le microphone, remplacer une batterie,
      des boutons ou des circuits... Grâce à notre expertise et à la qualité de
      nos techniciens, nous réalisons un large choix de prestations sur votre
      smartphone avec une qualité de service irréprochable.
    </p>
  `,
  HUAWEI: `
    <h3>
      Faites réparer votre smartphone Huawei auprès du leader de la réparation
      en France
    </h3>
    <p>
      Géant chinois de la téléphonie, Huawei s’est installé depuis quelques
      années sur le marché français à travers plusieurs modèles à succès (comme
      les P8, P20 ou P30). Save est le spécialiste de la
      <strong>réparation de smartphones Huawei</strong> en France. Faites
      confiance à nos équipes de <strong>techniciens experts</strong> et à notre
      agrément constructeur pour faire réparer votre téléphone durablement et en
      un temps record.
    </p>
    <p>
      Pratique, nous disposons d’un réseau complet de près de 200 magasins
      présents dans toute la France. Nous vous proposons une prise en charge
      expresse en créant votre bon de réparation via cette page, avant de le
      présenter en magasin pour bénéficier d’un diagnostic complet et d’une
      réparation immédiate.
    </p>
    <h3>Réparateur Huawei de proximité avec près de 200 adresses en France</h3>
    <p>
      Save est le plus grand réseau de France en réparation de
      smartphones toutes marques confondues. Avec des adresses réparties sur
      tout le territoire, vous disposez toujours d’un centre de réparation agréé
      près de chez vous. Cette couverture complète nous a permis de développer
      une relation de confiance en tant que réparateur Huawei. Nos équipes sont
      toujours accessibles et à votre disposition pour un diagnostic sur place.
      En combinant réactivité et efficacité, nous assurons la meilleure
      prestation possible pour remettre votre smartphone Huawei en état de
      marche, rapidement.
    </p>
    <h3>Des réparations de smartphones tout-terrain en un temps record</h3>
    <p>
      Nos techniciens experts assurent une large gamme de prestations de remise
      en état en cas d’avarie. En magasin, vous bénéficiez d’un diagnostic
      complet et d’une <strong>réparation en 40 minutes (dans 82% des cas)
      </strong>.En cas d’immobilisation du téléphone, nous vous proposons un
      smartphone de remplacement pendant la durée de la restauration.
    </p>
    <p>
      Quel que soit le problème, Save est le prestataire idéal
      pour réparer votre téléphone Huawei. Nous travaillons avec tous les
      principaux modèles (P8, P9, P10, P20, séries Y, Mate, Ascend, Nexus...) en
      proposant un service irréprochable et une expertise tout-terrain. Nos
      spécialistes opèrent tous types de réparations en fonction de la panne :
      changement d’écran LCD/dalle/vitre tactile, problème d’écran noir,
      téléphone tombé dans l’eau, oxydé ou cassé, disfonctionnement de la
      batterie, des haut-parleurs internes, du microphone ou des caméras,
      remplacement de boutons physiques, etc...
    </p>
    <h3>Agrément constructeur et Garantie 1 an</h3>
    <p>
      Faites confiance à un expert en réparation de smartphones, toutes marques.
      Save dispose d’un <strong>agrément constructeur</strong>
      avec Huawei qui nous permet de disposer des pièces détachées d’origine.
      Nos réparations font l’objet d’une <strong>garantie d’un an</strong> pour
      une tranquillité totale.
    </p>
  `,
  APPLE: `
  <h3>
    Faites réparer votre iPhone par le leader de la réparation en France
  </h3>
  <p>
    Votre iPhone est cassé ou en panne ? Faites appel à un expert de la réparation 
    de smartphone Apple. Save propose un réseau complet de spécialistes en téléphones 
    mobiles présents dans toute la France. Bénéficiez d’un diagnostic express et d’une 
    réparation immédiate selon les standards Apple les plus élevés, que ce soit pour 
    changer un écran cassé, une batterie épuisée ou n'importe quelle autre panne. 
    Toutes nos prestations font l’objet de la plus haute exigence et sont <strong>garanties 
    1 an</strong> pour profiter au maximum de votre iPhone et retarder l’obsolescence.
  </p>
  <p>
    Nos magasins participent à l’IRP d’Apple afin de vous offrir la réparation de la meilleure 
    qualité possible. L’IRP est le programme de réparation d'Apple à destination des indépendants 
    qui permet à Save d'avoir accès aux pièces détachées d'origine, aux outils et aux formations. 
    Nos techniciens sont donc formés et outillés selon les standards d'Apple. Cela nous permet de 
    proposer des réparations pour votre iPhone de la meilleure qualité possible et adaptées aux 
    besoins de chacun !
  </p>
  <h3>Un réparateur iPhone près de chez vous, près de 200 adresses en France</h3>
  <p>
    Premier réseau de services pour smartphones en France, nos points de vente assurent une couverture complète 
    du territoire avec des magasins dans toutes les principales villes. Réactifs et efficaces, nous réalisons vos 
    <strong>réparations en moins d’une heure (dans 82% des cas)</strong>.
  </p>
  <p>
    Proches de nos clients, nous avons établi une relation de confiance grâce à des équipes à la fois disponibles
    et réactives. Nous mettons en œuvre un savoir-faire et des pièces détachées de qualité. Pour en profiter,
    vous pouvez vous présenter directement dans le Save le plus proche de chez vous. 
    Votre iPhone 12 ne s’allume plus ? Vous ne savez pas comment changer la batterie de votre iPhone X qui ne 
    tient plus la journée ? Vous voulez savoir combien coûte le changement de votre vite ? Vous pouvez bénéficier
    d’une prise en charge encore plus rapide en créant votre bon de réparation depuis cette page en indiquant le
    modèle d’iPhone en panne.
  </p>
  <p>
    Save est le magasin qu’il vous fait pour le changement de votre vitre cassée !
  </p>
  <h3>Réparations tous modèles, quelle que soit la panne</h3>
  <p>
    Nos équipes de <strong>techniciens experts</strong> prennent en charge tous les smartphones Apple, 
    de l’iPhone 4 aux tout derniers modèles (iPhone 13 Pro Max, iPhone 12 mini, iPhone 11 Pro)
    en passant par les iPhone X, XR, 8, 8+, 7, 6S, SE… Quelle que soit la version, nous sommes
    spécialisés dans la réparation de tous types d’endommagement en cas d’écran cassé, de remplacement
    de dalle LCD/OLED/vitre tactile, de téléphone tombé dans l’eau, cassé ou oxydé, de problème de 
    batterie ou de connectivité, de changement du bouton Home ou Démarrer, du dock de charge, des 
    caméras avant ou arrière, etc… Quelle que soit la panne à résoudre, nos équipes vous assurent 
    une remise en état de marche à la fois rapide et fiable sur le long terme.
  </p>
  <h3>Garantie 1 an</h3>
  <p>
    Nous vous proposons des réparations efficaces et instantanées, idéales en cas de panne récente. 
    En faisant appel à nos spécialistes, vous pourrez préserver la durée de vie et la valeur de votre 
    téléphone. Toutes les prestations sont opérées par des techniciens spécialistes des produits Apple 
    et font l’objet d’une garantie d’un an, pour une tranquillité totale.
  </p>
`,
}

export default cgus
