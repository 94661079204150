import { graphql, PageProps } from 'gatsby'
import React, { useCallback, useMemo } from 'react'
import Highlights from '../components/Highlights'
import RepairContent from '../components/RepairContent'
import highlights from '../data/highlights'
import { RepairModelsQuery } from '../_generated/codegen/graphqlTypes'
import notEmpty from '../utils/notEmpty'
import { Unpacked } from '../utils/Unpacked'
import { RepairBrandPageContext } from './RepairBrandPage.context'
import { repairModelPageBuilder } from './RepairModelPage.context'
import { Helmet } from 'react-helmet'
import cgus from '../data/cgus'
import useSaveBrandsQuery from '../hooks/useBrandsQuery'
import capitalizeFirstLetter from '../utils/capitalizeFirstLetter'

const usePathBuilder = (brandName: string) => {
  return useCallback(
    (item: IRepairModel) => {
      return repairModelPageBuilder(brandName, item.slugFull ?? '')
    },
    [brandName]
  )
}

const useModels = (data: RepairModelsQuery): IRepairModel[] => {
  return useMemo(() => {
    return data.allSaveModel.nodes.filter(notEmpty) ?? []
  }, [data])
}

const RepairBrandPage: React.FC<
  PageProps<RepairModelsQuery, RepairBrandPageContext>
> = ({ data, pageContext }) => {
  const models = useModels(data)
  const brands = useSaveBrandsQuery()

  const pathBuilder = usePathBuilder(pageContext.brandName)

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={`Réparez votre smartphonessssss ${capitalizeFirstLetter(
            pageContext.brandName
          )} avec le leader européen de la réparation de proximité dans un de nos ${
            pageContext.shopsCount
          } points de vente Save - Réparation garantie 1 an.`}
        />
        <meta
          property="og:image"
          content={
            brands.filter((brands) => pageContext.brandName == brands.name)[0]
              .imgUrl ??
            `${process.env.GATSBY_SITE_URL}images/logo-Save-blanc-v-blanc.png`
          }
        />
      </Helmet>

      <RepairContent>
        <RepairContent.Card
          index={2}
          title={
            <RepairContent.SeoHeader
              title={`Réparation de mon téléphone ${capitalizeFirstLetter(
                pageContext.brandName
              )}`}
              subtitle="Choisir le modèle de mon smartphone"
            />
          }
        >
          <RepairContent.Grid
            items={models}
            pathBuilder={pathBuilder}
            withSearch="Rechercher un modèle"
            type={"models"}
          />
        </RepairContent.Card>

        <Highlights items={highlights} />

        {cgus[pageContext.brandName] && (
          <RepairContent.Cgu
            dangerouslySetInnerHTML={{ __html: cgus[pageContext.brandName] }}
          />
        )}
      </RepairContent>
    </>
  )
}

type IRepairModel = NonNullable<
  Unpacked<NonNullable<RepairModelsQuery['allSaveModel']['nodes']>>
>

export const query = graphql`
  query RepairModels($brandName: String!) {
    allSaveModel(
      filter: { brandName: { eq: $brandName } }
      sort: { fields: weigth, order: DESC }
    ) {
      nodes {
        imgUrl
        imageFile {
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 100, width: 170)
          }
        }
        name
        slugFull
      }
    }
  }
`

export default RepairBrandPage
